import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import {Container, Row, Col} from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <header className="blogHeader">
        <Img className="blogHeader__figure" alt={post.frontmatter.title} fluid={post.frontmatter.header.childImageSharp.fluid} />
        <div className="blogHeader__title">

          <span className="blogHeader__date">{post.frontmatter.date}</span>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          
        </div>
      </header>

      <Container className="py-5">
        <Row>
          <Col xs={{'size': 12, 'offset': 0}} sm={{'size': 10, 'offset': 1}} md={{'size': 8, 'offset': 2}}>
          
            <article itemScope itemType="http://schema.org/Article">
              <section
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
                className="blogArticleBody"
              />
            </article>

          </Col>
        </Row>
      </Container>





    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        description
        header{
          childImageSharp{
            fluid(maxWidth:1600, quality:100){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
